import React, { FC } from 'react'

import Copyright from '../../Copyright/old'
import Social from '../../Social/old'
import IconGooglePlay from '../../IconGooglePlay/old'
import IconAppStore from '../../IconAppStore/old'

import PolicyPLDFT from '../../../docs/Cora_SCD_Politica_PLDFT.pdf'
import * as S from './styled'

interface FooterProps {
  storeLink: string
}

const Footer: FC<FooterProps> = ({ storeLink }: FooterProps) => (
  <S.FooterWrapper>
    <div className="container">
      <S.FooterContent>
        <S.FooterRow>
          <S.ListWrapper>
            <S.ListTitle>Sobre nós</S.ListTitle>
            <S.List>
              <li>
                <S.Link href="https://www.cora.com.br/cultura/">Cultura</S.Link>
              </li>
              <li>
                <S.Link
                  href="https://www.cora.com.br/vagas-cora"
                  target="_blank"
                >
                  Carreiras
                </S.Link>
              </li>
              <li>
                <S.Link href="https://www.cora.com.br/blog">Blog</S.Link>
              </li>
            </S.List>
          </S.ListWrapper>
          <S.ListWrapper>
            <S.ListTitle>Produto</S.ListTitle>
            <S.List>
              <li>
                <S.Link href="/">Conta Digital</S.Link>
              </li>
              <li>
                <S.Link href="/conta-pj/boletos">Boletos</S.Link>
              </li>
            </S.List>
          </S.ListWrapper>
          <S.ListWrapper>
            <S.ListTitle>Suporte</S.ListTitle>
            <S.List>
              <li>
                <S.Link href="http://meajuda.cora.com.br/">FAQ</S.Link>
              </li>
              <li>
                <S.Link href="mailto:meajuda@cora.com.br">
                  meajuda@cora.com.br
                </S.Link>
              </li>
            </S.List>
          </S.ListWrapper>
          <S.ListWrapper>
            <S.ListTitle>Transparência</S.ListTitle>
            <S.List>
              <li>
                <S.Link href="/regulatorio">Regulatório</S.Link>
              </li>

              <li>
                <S.Link href={PolicyPLDFT} target="_blank">
                  Parceiros e Fornecedores
                </S.Link>
              </li>

              <li>
                <S.Link href="/ouvidoria">Ouvidoria</S.Link>
              </li>
            </S.List>
          </S.ListWrapper>
        </S.FooterRow>
        <S.FooterRow>
          <S.ListWrapper className="store-list">
            <S.ListTitle>Baixe o app</S.ListTitle>
            <S.LinkStoresContainer>
              <IconGooglePlay className="store-icon" small link={storeLink} />
              <IconAppStore className="store-icon" small link={storeLink} />
            </S.LinkStoresContainer>
          </S.ListWrapper>
        </S.FooterRow>
      </S.FooterContent>
    </div>
    <S.FooterCopyright>
      <div className="container">
        <S.FooterCopyrightContent>
          <Copyright />

          <Social />
        </S.FooterCopyrightContent>
      </div>
    </S.FooterCopyright>
  </S.FooterWrapper>
)

export default Footer
